import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer>
    <p>&copy; 2023 Password Manager. All rights reserved.</p>
  </footer>
  )
}

export default Footer